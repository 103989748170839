import Swiper, { Autoplay, EffectFade, Pagination } from 'swiper';
import MicroModal from 'micromodal';

MicroModal.init();
Swiper.use([Autoplay, EffectFade, Pagination]);

global.urlParams = {};

const modalExternalProceed = document.getElementById('modal-external-proceed');

document.querySelectorAll('a:not(.suppress-external)').forEach(function(a) {
  const href = a.getAttribute('href');

  if (location.hostname !== a.hostname && a.hostname.length) {
    a.addEventListener('click', function(e) {
      e.preventDefault();

      MicroModal.show('modal-external');

      modalExternalProceed.addEventListener('click', function() {
        window.location.href = href;
      });
    });
  }
});

// Get query string params: https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript#answer-2880929
(window.onpopstate = function () {
  var match,
      pl     = /\+/g,  // Regex for replacing addition symbol with a space
      search = /([^&=]+)=?([^&]*)/g,
      decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
      query  = window.location.search.substring(1);

  urlParams = {};
  while (match = search.exec(query))
    urlParams[decode(match[1])] = decode(match[2]);
})();

$(function () {
  // Swiper init
  new Swiper('.swiper-container', {
    loop: true,
    effect: 'fade',
    autoplay: {
      delay: 15000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
  });

  var acc = document.getElementsByClassName('accordion'),
      i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener('click', function() {
      this.classList.toggle('active');
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      }
    });
  }
});
